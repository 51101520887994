<template>
  <dialogComp :title="titleD" :isShow="isShow" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="类目" prop="categoryId" v-if="title === 'add' || title === 'edit' ">
        <goodCategory
          :hval="formData.categoryId"
          placeholder="请选择类目"
          @change="categoryIdChange"
        />
      </el-form-item>
      <inputFormComp
        inputType="number"
        label="顺序:"
        prop="orderNum"
        :hval="formData.orderNum"
        @blur="orderNumBlur"
        placeholder="请输入顺序"
      />
      <inputFormComp
        label="故障现象:"
        prop="appearance"
        :hval="formData.appearance"
        @blur="appearanceBlur"
        placeholder="请输入故障现象"
      />
      <inputFormComp
        inputType="textarea"
        label="解决步骤:"
        prop="remark"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入解决步骤"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import goodCategory from '@/views/components/goodCategory'
import { deviceFaultAdd, deviceFaultEdit } from '@/api'
export default {
  components: {
    goodCategory
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formData: {
        categoryId: null,
        categoryName: '',
        orderNum: '',
        appearance: '',
        remark: ''
      },
      rules: {
        categoryId: [
          { required: true, message: '请选择类目', trigger: 'chang' }
        ],
        orderNum: [{ required: true, message: '请输入顺序', trigger: 'blur' }],
        appearance: [
          { required: true, message: '请输入故障现象', trigger: 'blur' }
        ],
        remark: [{ required: true, message: '请输入解决步骤', trigger: 'blur' }]
      }
    }
  },
  computed: {
    titleD () {
      return (this.title === 'addC' || this.title === 'editC') ? '新增设备故障' : '编辑设备故障'
    }
  },
  watch: {
    isShow (val) {
      if (val && (this.title === 'edit' || this.title === 'editC')) {
        const {
          categoryId,
          categoryName,
          orderNum,
          appearance,
          remark
        } = this.info
        this.formData.categoryId = +categoryId
        this.formData.categoryName = categoryName
        this.formData.orderNum = orderNum
        this.formData.appearance = appearance
        this.formData.remark = remark
      }
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    categoryIdChange (val) {
      this.formData.categoryId = val ? val.resultId : ''
      this.formData.categoryName = val ? val.resultName : ''
    },
    orderNumBlur (val) {
      this.formData.orderNum = val
    },
    appearanceBlur (val) {
      this.formData.appearance = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData
          }
          // 区分是新增的是不是子故障
          if (this.title === 'addC' || this.title === 'editC') {
            const { id, categoryName, categoryId } = this.$route.query
            params.parentId = id
            params.categoryName = categoryName
            params.categoryId = categoryId
            params.level = 2
          } else {
            params.parentId = null
            params.level = 1
          }
          if (this.title === 'add' || this.title === 'addC') {
            deviceFaultAdd(params).then(res => {
              if (res.code === '1') {
                this.$message.success('新增成功')
                this.close()
              }
            })
          } else {
            deviceFaultEdit({ id: this.info.id, ...params }).then(
              res => {
                if (res.code === '1') {
                  this.$message.success('编辑成功')
                  this.close()
                }
              }
            )
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
