<template>
  <div class="layout_common device_manage">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <goodCategory
              :hval="categoryId"
              @change="categoryIdChange"
            />
            <inputComp
              placeholder="故障现象"
              :hval="appearance"
              @blur="appearanceBlur"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="add('add')" v-permission="'新增'" v-if="!compChild">新 增</btnComp>
            <btnComp @click="add('addC')" v-if="compChild" v-permission="'新增'">新 增</btnComp>
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchDel"
              v-if="!compChild"
              v-permission="'批量删除'"
              >批量删除</btnComp
            >
            <btnComp
              :disabled="allotDisabled"
              btnType="plain"
              @click="batchDel"
              v-if="compChild"
              v-permission="'批量删除'"
              >批量删除</btnComp
            >
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          isShowCheckBox
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @select="select"
          @selectAll="selectAll"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="add('edit', scope.row)" v-permission="'编辑'" v-if="!compChild"
                >编辑</btnComp
              >
              <btnComp btnType="text" @click="add('editC', scope.row)" v-permission="'编辑'"  v-if="compChild"
                >编辑</btnComp
              >
              <btnComp btnType="text" @click="childFault(scope.row)" v-permission="'子故障'"
                >子故障</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <add :isShow="addShow" @close="close" :title="title" :info="info" />
    <dialogComp
      title="提示"
      :isShow="hintShow"
      @close="close"
      @sure="sureDel"
    >
      <p>确定删除吗？</p>
    </dialogComp>
  </div>
</template>

<script>
import add from './add'
import goodCategory from '@/views/components/goodCategory'
import { deviceFaultList, deviceFaultDel } from '@/api'
export default {
  props: ['compChild'],
  components: {
    add,
    goodCategory
  },
  data () {
    return {
      addType: '',
      addShow: false,
      hintShow: false,
      title: '',
      info: {},
      selectIds: [],
      curPage: 1,
      pageSize: 10,
      total: 0,
      appearance: '',
      categoryId: null,
      tableData: [],
      theadName: [
        { prop: 'categoryName', label: '类目' },
        { prop: 'orderNum', label: '顺序' },
        { prop: 'appearance', label: '故障现象' },
        { prop: 'remark', label: '解决步骤' },
        { slot: 'operate' }
      ]
    }
  },
  computed: {
    allotDisabled () {
      return !(this.selectIds.length > 0)
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        appearance: this.appearance,
        categoryId: this.categoryId,
        parentId: this.$route.query.id ? this.$route.query.id : null,
        level: this.$route.query.id ? 2 : 1
      }
      deviceFaultList(params).then(res => {
        if (res.code === '1') {
          this.tableData = res.data.list
          this.total = res.data.totalCount
        }
      })
    },
    childFault (info) {
      const { id, categoryId, categoryName } = info
      this.$router.push({ name: 'child-fault', query: { id, categoryName, categoryId } })
    },
    select (val) {
      this.selectIds = val.map(item => item.id)
    },
    selectAll (val) {
      this.selectIds = val.map(item => item.id)
    },
    categoryIdChange (val) {
      this.categoryId = val ? val.resultId : ''
    },
    appearanceBlur (val) {
      this.appearance = val
    },
    batchDel () {
      this.hintShow = true
    },
    sureDel () {
      const params = {
        ids: this.selectIds
      }
      deviceFaultDel(params).then(res => {
        if (res.code === '1') {
          this.$message.success('删除成功')
          this.close()
        }
      })
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.categoryId = ''
      this.appearance = ''
      this.getList()
    },
    add (val, info) {
      this.title = val
      if (val === 'edit' || val === 'editC') {
        this.info = info
      }
      this.addShow = true
    },
    close () {
      this.addShow = false
      this.hintShow = false
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>

<style></style>
